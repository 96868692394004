import styled from "styled-components"
import { Button, Input, Form, Tag } from 'antd'
import { Link } from "react-router-dom"

const { TextArea } = Input
export const MainDetails = styled.div`
  .ant-tabs-content .content-table{
    padding: 0;
  }
  @media(max-width: 767px){
    .rp-mb{
      margin-bottom: 30px;
    }
    .ant-row{
      margin-bottom: 15px;
    }    
  }
`

export const BoxContentPlaceHolder = styled.div`
    background: ${({ theme }) => theme.bg2};
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
    padding: 15px 24px;
    height: 100%;
    @media(max-width: 767px){
      padding-left: 15px;
      padding-right: 15px;
    }
`
export const InnerPlaceHolder = styled.div`
`
export const TxtColStyle = styled.div`
    margin: 10px 0;
    &.col-txt-left {
      color: ${({ theme }) => theme.textOverlay};
      font-size: 13px;
    }
    &.col-txt-right {
      color: ${({ theme }) => theme.text};
    }
    &.bg-box {
      background-color: ${({ theme }) => theme.bg4};
      padding: 24px;
      @media(max-width: 767px){
          padding: 15px;
      }
      .style-table-bordered {
          padding: 24px;
          @media(max-width: 767px){
              padding: 15px;
          }
      }
  }
    @media(max-width: 767px){
      margin: 0;
    }
`
export const BoxContentTable = styled.div`
    margin-top: 25px;
    position: relative;
`
export const TitleHeaderBox = styled.div`
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 15px;
    border-bottom: 1px solid ${({ theme }) => theme.border};
    padding-bottom: 15px;
    color: ${({ theme }) => theme.text};
    .anticon{
      color: ${({ theme }) => theme.primary};
    }
`
export const NoteInfo = styled.div`
  margin: 5px 0 20px 0px;
  color: ${({ theme }) => theme.text};
  .flex-between{
    width: 100%;
    display: flex;
    gap: 12px;
    justify-content: space-between; 
    background-color: #FDF5E2;
    padding: 12px 16px;
    @media(max-width: 767px){
      flex-direction: column;
    }
  }
`
export const ContractSource = styled.pre<{ height?: string }>`
    background-color: ${({ theme }) => theme.bg};
    white-space: pre-wrap;
    height: ${({ height }) => height ? height : '20px'};
    padding: 10px;
    word-wrap: break-word;
    border-radius: 0;
    border: 1px solid ${({ theme }) => theme.border};
    font-size: 12px;
    color:${({ theme }) => theme.textOverlay};
`
export const AddressBox = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    margin-top: -15px;
`
export const StyleCopyIcon = styled.div`
  border-radius: 0;
  cursor: pointer;
  border-radius: 0;
  border: 0;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.textOverlay};
  background-color: ${({ theme }) => theme.bg2};
  &:hover {
      background-color: ${({ theme }) => theme.bgHover};
  }
  > *{
    width: 32px;
    height: 32px;
    line-height: 32px;
    text-align: center;
  }
`
export const TextOverlay = styled.span`
    color: ${({ theme }) => theme.textOverlay};
`
export const TextMain = styled.span`
    color: ${({ theme }) => theme.text};
`
export const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
`
export const ButtonStyled = styled(Button)`
  border-radius: 0;
  &.ant-btn,
  &.ant-btn:hover,
  &.ant-btn:focus {
    border: 0;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({ theme }) => theme.textOverlay};
    background-color: ${({ theme }) => theme.bg2};
    &.ant-btn:hover {
      background-color: ${({ theme }) => theme.bgHover};
    }
  }
  > *{
    width: 32px;
    height: 32px;
    line-height: 32px;
    text-align: center;
  }
`
export const SwitchButton = styled.button`
  font-size: 12px;
  cursor: pointer;
  margin-right: 10px;
  display: inline-block;
  color: #fff;
  background-color: ${({ theme }) => theme.primary};
  border: 1px solid ${({ theme }) => theme.border};
  border-radius: 0;
`
export const Cards = styled.div`
  margin-bottom: 20px;
`
export const CardHeader = styled.div`
  border: 1px solid ${({ theme }) => theme.border};
  padding: 5px 10px;
  background-color:${({ theme }) => theme.bg};
`
export const CardContent = styled.div`
  padding: 5px 10px 20px 10px;
  border-left: 1px solid ${({ theme }) => theme.border}; 
  border-bottom: 1px solid ${({ theme }) => theme.border}; 
  border-right: 1px solid ${({ theme }) => theme.border};  
`
export const FormGroup = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
  label{
    font-weight: 500;
    color: ${({ theme }) => theme.text};
  }
  .ant-form .anticon-minus-circle{
    color: ${({ theme }) => theme.textOverlay};
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
`
export const FormItem = styled(Form.Item)`
  margin-bottom: 0;
  border-radius: 0;
  input{
    background-color: ${({ theme }) => theme.bg2};
    border: 1px solid ${({ theme }) => theme.border};
    color: ${({ theme }) => theme.textOverlay};
  }
  
`
export const InputStyled = styled(Input)`
  background-color: ${({ theme }) => theme.bg2};
  border: 1px solid ${({ theme }) => theme.border};
  color: ${({ theme }) => theme.textOverlay};
`
export const TextAreaStyled = styled(TextArea)`
  background-color: ${({ theme }) => theme.bg2};
  border: 1px solid ${({ theme }) => theme.border};
  color: ${({ theme }) => theme.textOverlay};
`
export const SelectStyled = styled.div`
  .ant-select-selector{
    background-color: ${({ theme }) => theme.bg2}!important;
    border: 1px solid ${({ theme }) => theme.border}!important;
    color: ${({ theme }) => theme.textOverlay};
  }
  .ant-select-arrow{
    color: ${({ theme }) => theme.textOverlay};
  }
`
export const ButtonQuery = styled(Button)`
  border: 1px solid ${({ theme }) => theme.border};
  background-color: ${({ theme }) => theme.bg};
  padding: 5px 15px;
  margin-top: 10px;
  border-radius: 0;
  margin-top: 20px;
  cursor: pointer;
  &:hover{
    background-color: ${({ theme }) => theme.bg1};
  }
`
export const LinkStyled = styled(Link)`
  padding: 5px 10px;
  background-color: ${({ theme }) => theme.primary};
  color: #fff;
  margin-right: 20px;
  margin-top: 15px;
  display: inline-block;
  border-radius: 0;
  &:hover{
    background-color: #0dcea3;
    color: #fff;
  }
`
export const BoxItemLog = styled.div`
    margin-bottom: 25px;
    padding-bottom: 25px;
    border-bottom: 1px solid ${({ theme }) => theme.border};
`
export const DataType = styled.div`
    margin-bottom: 15px;
`
export const ButtonType = styled.button`
    background-color: ${({ theme }) => theme.border};
    border: none;
    padding: 5px 10px;
    height: 20px;
    line-height: 10px;
    cursor: pointer;
    &.active{
        background-color: ${({ theme }) => theme.primary};
        color: #fff;
    }
`
// Verify Box

export const Box = styled.div`
    background-color: ${({ theme }) => theme.bg2};
    padding: 24px;
    border-radius: 0;
`
export const FieldExtend = styled.div`
    padding: 24px;
    background-color: ${({ theme }) => theme.bg4};
    border-radius: 0;
    margin-bottom: 20px;
    width: 100%;
`
export const ButtonSubmit = styled(Input)`
    cursor: pointer;
    width: auto;    
    padding: 5px 40px;
    font-weight: 500;
    background-color: ${({ theme }) => theme.primary};
    color: #fff;
    border: none;
`
export const AddSource = styled(Button)`
  background-color: ${({ theme }) => theme.bg2};
  color: ${({ theme }) => theme.text};
  font-weight: 500;
  &:hover{
    background-color: ${({ theme }) => theme.bg2};
  }
`
export const StatusStyled = styled.p<{ status: string }>`
    color: ${({ theme, status }) => theme[status]};
    font-weight: 500;
`

export const VerifiedIcon = styled(Tag)`
color: ${({ theme }) => theme.success};
border: 0;
border-radius: 0;
margin: 0;
width: 25px;
background: transparent;
`

export const RelativeDiv = styled.div`
  position: relative
`

export const BlockCenter = styled.div`
  display: flex;
  
  display: flex;
  align-items: center;
  justify-content: center;
`