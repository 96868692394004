import { useCallback, useEffect, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Helmet } from 'react-helmet-async'
import { QRCode } from 'react-qr-svg'
import { Redirect, useHistory, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import iconCopy from '../../assets/images/icon/icon-copy.svg'
import iconQrcode from '../../assets/images/icon/icon-qrcode.svg'
import { DataTableTotal, Datatable, ExportData, SkeletonLoading, TabsWithHash, columnsTransactions } from '../../common'
import { PageHeader, TagWarningAddress } from '../../common/PageHeader'
import { tagWarningAddress } from '../../constants'
import { TokenParamsType } from '../../constants/types'
import { formatNumber, isAddress, timeFormatUtc, toTomo } from '../../utils'

import {
  QuestionCircleOutlined,
} from '@ant-design/icons'
import {
  Col,
  Row,
  Space,
  Tabs,
  Tooltip,
  Typography,
} from 'antd'
import { useSelector } from 'react-redux'
import { RootState } from '../../app/store'
import { TabStyle } from '../../appStyles'
import Colors from '../../constants/themeConfig'
import { buildD3Domain, buildOneIdSuggestions } from '../../domainServices'
import ContractDetailsPage from '../contracts/ContractDetailsPage'
import InternalTxns from './InternalTxns'
import ListTokenBalance from './ListTokenBalance'
import TotalTokenBalance from './TotalTokenBalance'
import UserRewards from './UserRewards'
import VrcTokenTxns from './VrcTokenTxns'
import {
  AddressBox,
  BoxContentPlaceHolder,
  BoxContentTable,
  ButtonStyled,
  Center,
  InnerPlaceHolder,
  MainDetails,
  StyleCopyIcon,
  TextStyle,
  TitleHeaderBox,
  TxtColStyle,
} from './accountDetailsPageStyles'
import {
  getAccountDetails,
  getTokenTransactions,
  resetAccountDetail
} from './accountsSlice'

const { Paragraph } = Typography;
const { TabPane } = Tabs

export default function AccountDetailsPage() {
  const history = useHistory()

  const [isCopied, setCopied] = useState(false)
  const [loadingDomain, setLoadingDomain] = useState(true);
  const [activeKey, setActiveKey] = useState('')

  const { address } = useParams<TokenParamsType>()
  const dispatch = useAppDispatch()
  const mode = useSelector((state: RootState) => state.general.mode)

  const getTransactionData = useCallback(
    (params: any) => getTokenTransactions({ ...params, address }),
    [address]
  )

  const handleSearchAccount = async () => {
    let validAddress = address;
    if (!validAddress) return;
    try {
      if (!isAddress(validAddress)) {
        setLoadingDomain(true);
        validAddress = (await buildOneIdSuggestions(address as string))[0]?.address;
        validAddress = validAddress ?? (await buildD3Domain(address as string))[0]?.address;

        if (validAddress) {
          history.push('/address/' + validAddress);
          return;
        }
      }
    } catch (error) { }
    
    if (!validAddress) {
      history.push(`/search?q=${address}`);
      return;
    }
    dispatch(getAccountDetails(validAddress))
    setLoadingDomain(false);
  }

  useEffect(() => {
    handleSearchAccount();
  }, [address])

  useEffect(() => {
    return () => {
      dispatch(resetAccountDetail())
    }
  }, [])

  const { data, loading, tokensInAccountForDropdown, transactions } = useAppSelector((state) => state['accounts']['details'])

  if (loading === 'pending' || loadingDomain) return <SkeletonLoading />
  if (loading === 'failed') return <Redirect to={`/search?q=${address}`} />
  if (!data) return null

  if (data.isToken && !data.isContract) return <Redirect to={`/token/${data.address}`} />
  if (data.isContract) return <ContractDetailsPage data={data} />

  const totalBalance = data.tomoInUsd + tokensInAccountForDropdown.usdValue
  return (
    <MainDetails>
      {address &&
        <Helmet>
          <title>{`Address ${address}`}</title>
        </Helmet>
      }
      <PageHeader title="Address" />
      <AddressBox>
        <TextStyle style={{ marginRight: '10px', wordBreak: "break-word" }}>{data ? data.address : ''}</TextStyle>
        <Tooltip placement="top" title={isCopied ? "Copied" : "Copy address to clipboard"}>
          <CopyToClipboard
            text={data ? data.address : ''}
            onCopy={() => setCopied(true)}
            //@ts-ignore
            onMouseEnter={() => setCopied(false)}
          >
            <StyleCopyIcon style={{ marginRight: '10px' }}>
              <span><img width="20" src={(iconCopy)} alt="icon copy" /></span>
            </StyleCopyIcon>
          </CopyToClipboard>
        </Tooltip>
        <Tooltip
          placement="bottom"
          color="#ffffff"
          title={
            <Center>
              <QRCode
                level="Q"
                style={{ width: 150 }}
                value={data ? data.address : ''}
              />
            </Center>
          }
        >
          <ButtonStyled icon={<span><img width="20" src={(iconQrcode)} alt="icon QRCODE" /></span>} />
        </Tooltip>
      </AddressBox>

      {address && tagWarningAddress[address] &&
        <div>
          <Paragraph style={{ color: Colors(mode).redAlert, marginBottom: "10px" }}>
            {tagWarningAddress[address]?.text}
            <a style={{ marginLeft: "5px", marginRight: "5px" }} target="_blank" rel="noreferrer" href={tagWarningAddress[address]?.link} >
              Detail here
            </a>
            <div>Please exercise caution when interacting with this address.</div>
            {Array.isArray(tagWarningAddress[address]?.tag) ?
              tagWarningAddress[address]?.tag.map((t: string, i: any) => (
                <TagWarningAddress key={i}>{t}</TagWarningAddress>
              ))
              : ''
            }
          </Paragraph>
        </div>
      }

      <div>
        <Row gutter={24}>
          <Col span={24} md={12} className="rp-mb">
            <BoxContentPlaceHolder>
              <TitleHeaderBox>Overview</TitleHeaderBox>
              <InnerPlaceHolder>
                <Row gutter={24}>
                  <Col span={24} md={5} lg={8}>
                    <TxtColStyle className="col-txt-left">
                      Total Balance
                    </TxtColStyle>
                  </Col>
                  <Col span={24} md={19} lg={16}>
                    <TxtColStyle className="col-txt-right">
                      ${formatNumber(totalBalance, 4)}
                    </TxtColStyle>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={24} md={5} lg={8}>
                    <TxtColStyle className="col-txt-left">
                      VIC Balance
                    </TxtColStyle>
                  </Col>
                  <Col span={24} md={19} lg={16}>
                    <TxtColStyle className="col-txt-right">
                      <Space>
                        {formatNumber(toTomo(data.balance, 18), 2)} VIC <TextStyle>({`$${formatNumber(data.tomoInUsd, 2)}`})</TextStyle>
                        <Tooltip title={() => <TomoToolTip tomoPrice={data.tomoPrice} />}><QuestionCircleOutlined /></Tooltip>
                      </Space>
                    </TxtColStyle>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={24} md={5} lg={8}>
                    <TxtColStyle className="col-txt-left">
                      Other VRC Token Balance
                    </TxtColStyle>
                  </Col>
                  <Col span={24} md={19} lg={16}>
                    <TxtColStyle className="col-txt-right">
                      <ListTokenBalance showMore={() => setActiveKey('totalBalance')} />
                    </TxtColStyle>
                  </Col>
                </Row>
              </InnerPlaceHolder>
            </BoxContentPlaceHolder>
          </Col>
          <Col span={24} md={12}>
            <BoxContentPlaceHolder>
              <TitleHeaderBox>More information</TitleHeaderBox>
              <InnerPlaceHolder>
                <Row gutter={24}>
                  <Col span={24} md={5} lg={8}>
                    <TxtColStyle className="col-txt-left">
                      Public Name Tag
                    </TxtColStyle>
                  </Col>
                  <Col span={24} md={19} lg={16}>
                    <TxtColStyle className="col-txt-right">
                      {data.accountName ? data.accountName : "Not Available."}
                    </TxtColStyle>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={24} md={5} lg={8}>
                    <TxtColStyle className="col-txt-left">
                      Transaction Count
                    </TxtColStyle>
                  </Col>
                  <Col span={24} md={19} lg={16}>
                    <TxtColStyle className="col-txt-right">
                      {transactions ? transactions.total : 0} txs
                    </TxtColStyle>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={24} md={5} lg={8}>
                    <TxtColStyle className="col-txt-left">
                      Last transaction
                    </TxtColStyle>
                  </Col>
                  <Col span={24} md={19} lg={16}>
                    <TxtColStyle className="col-txt-right">
                      {timeFormatUtc(data.timestamp)}
                    </TxtColStyle>
                  </Col>
                </Row>
              </InnerPlaceHolder>
            </BoxContentPlaceHolder>
          </Col>
        </Row>

        <BoxContentTable>
          <TabStyle>
            {/* @ts-ignore */}
            <TabsWithHash onChange={(activeKey) => setActiveKey(activeKey)} type="card" activeTab={activeKey} tabKeys={["internalTxns", "vrcTokenTxns", "userRewards", "totalBalance"]}>
              <TabPane tab="Transactions" key="default">
                <ExportData account={data.address} type="transaction" title="Transactions" />
                <Datatable
                  loading={transactions.loading}
                  total={transactions.total}
                  columns={columnsTransactions}
                  dataSource={transactions.data}
                  header={<DataTableTotal total={transactions.total} type="tx" />}
                  getData={getTransactionData}
                />
              </TabPane>
              <TabPane tab="Internal Txns" key="internalTxns">
                <ExportData account={data.address} type="internalTx" title="Internal Txns" />
                <InternalTxns />
              </TabPane>
              <TabPane tab="Token Txns" key="vrcTokenTxns">
                <ExportData account={data.address} type="tokenTx" title="Token Txns" />
                <VrcTokenTxns />
              </TabPane>
              <TabPane tab="Rewards" key="userRewards">
                <ExportData account={data.address} type="userRewards" title="Rewards" />
                <UserRewards />
              </TabPane>
              <TabPane tab="Total Balance" key="totalBalance">
                <TotalTokenBalance />
              </TabPane>
            </TabsWithHash>
          </TabStyle>
        </BoxContentTable>
      </div>
    </MainDetails >
  )
}
const TomoToolTip = ({ tomoPrice }: any) => {
  return (
    <>
      <Paragraph style={{ color: "#fff", textAlign: "center", fontSize: "11px", marginBottom: "0px" }}>
        {`$${formatNumber(tomoPrice, 2)}/VIC`}
      </Paragraph>
      <Paragraph style={{ color: "#fff", textAlign: "center", fontSize: "11px", marginBottom: "0px" }}>
        Price oracle from &nbsp;
        <a href="https://www.coingecko.com/en/coins/tomochain" target="blank">Coingecko</a>
      </Paragraph>
    </>
  )
}

