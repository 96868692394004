import React from 'react';
import { PageHeader } from '../../common';
import { BlockCol, MainContent, Quotation, Section } from './ProxyVerifyStyle';
import Text from 'antd/lib/typography/Text'

import icDisclaimer from '../../assets/images/icon/icon-infor-2.svg'
import FormInputContract from './FromInputContract';

interface Props {
  className?: string,
}

const ProxyVerify: React.FC<Props> = ({
  className,
  ...props
}) => {
  return (
    <div >
      <PageHeader title="Proxy Contract Verification" />

      <MainContent>
        <Section
          style={{
            maxWidth: '60%'
          }}
        >
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '20px'
          }}>
            <Text>
              {`This page allows proxy contracts to be linked with their implementation ABIs and contract, thereby making it more intuitive for users to interact and know which contract their proxy address is connected with.`}
            </Text>
            <Quotation>
              <img style={{ height: '22px', width: '16px' }} src={icDisclaimer} alt="" />
              <BlockCol>
                <Text style={{ fontWeight: '700' }}>{`Disclaimer`}</Text>
                <Text>
                  {`The automated process uses programmatic heuristics which may not be accurate despite flagging this contract as a proxy contract. Please practice caution whenever interacting with contracts on Viction.`}
                </Text>
              </BlockCol>
            </Quotation>
          </div>
        </Section>
        <Section style={{
          width: '100%',
        }}>
          <div>
            <Text>
              {`Enter the Proxy Contract Address you want to verify:`}
            </Text>
            <FormInputContract />
          </div>
        </Section>
      </MainContent>
    </div>
  );
};

export default ProxyVerify;