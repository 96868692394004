import { Button, Modal } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { Quotation } from './ProxyVerifyStyle';

import icSuccess from '../../assets/images/icon/icon-success.svg';
import icError from '../../assets/images/icon/icon-failed.svg';
import { Link } from 'react-router-dom';

enum STATUS {
  SUCCESS = '1',
  FAILED = '0',
}

interface Props {
  className?: string,
  open: boolean;
  status: `${STATUS}`,
  message: string | React.ReactNode,
  onClose?: () => void;
}

const PopupActionVerify: React.FC<Props> = ({
  className,
  open,

  status = STATUS.SUCCESS,
  message,
  onClose,
  ...props
}) => {

  const VERIRY_STATUS = {
    [STATUS.SUCCESS]: {
      icon: icSuccess,
      text: 'The proxy contract verification completed with the message:'
    },
    [STATUS.FAILED]: {
      icon: icError,
      text: 'Something went wrong:'
    }
  }

  const handleClose = () => {
    onClose && onClose()
  }

  return (
    <ModalStyle
      centered
      open={open}
      footer={null}
      closable={false}
    >
      <Body>
        <VerifyStatus>
          <Icon
            src={VERIRY_STATUS[status].icon}
          />
          <Text>{VERIRY_STATUS[status].text}</Text>
        </VerifyStatus>
        <Quotation>
          <TextSub>
            {message}
          </TextSub>
        </Quotation>
      </Body>
      <ButtonGroup>
        <ButtonSecondary>
          <MailButton href={'mailto:hi@viction.xyz'}>
            {`Contact Us`}
          </MailButton>
        </ButtonSecondary>
        <ButtonPrimary onClick={handleClose}>
          {`Close`}
        </ButtonPrimary>
      </ButtonGroup>
    </ModalStyle>
  );
};

const ModalStyle = styled(Modal)`
  .ant-modal-body {
    padding: 32px;
  }
`

const ButtonGroup = styled.div`
  display: flex;
  gap: 16px;

  margin-top: 16px;
`
const ButtonStyle = styled(Button)`
  width: 100%;
  padding: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  flex-basic: 100%;

  border: none;
  [ant-click-animating-without-extra-node='true']::after{display:none;}
`
const ButtonPrimary = styled(ButtonStyle)`
  background-color: #766B60 !important;
  color: #fff !important;
  &:hover{
    opacity: 0.7;
  }
`
const ButtonSecondary = styled(ButtonStyle)`
  background-color: #F0EFEB  !important;
  color: #000 !important;
  &:hover{
    opacity: 0.7;
  }
`

const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
const VerifyStatus = styled.div`
  display: flex;
  align-items: start;

  gap: 8px;
`
const TextSub = styled.span`
  color: #666;
  font-weight: 400;
  font-size: 14px;
`

const Text = styled.span`
  font-weight: 600;
  font-size: 15px;
`
const Icon = styled.img`
  width: 16px;
  height: 24px;
`
const MailButton = styled.a`
  width: 100%;
`

export default PopupActionVerify;