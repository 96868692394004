import React, { useEffect, useState } from 'react';

import { Form, Input } from 'antd';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { postVerifyProxyAddress } from '../../api';
import { isAddress, isContract } from '../../utils';
import PopupActionVerify from './PopupActionVerify';
import { ButtonTheme, FormItem } from './ProxyVerifyStyle';
import styled from 'styled-components';
import RenderIf from '../../common/RenderIf';


const DEFAULT_STATUS: IVerifyStatus = { message: '', status: '0' }
const ERROR_MSG = {
  'EMPTY_ADDRESS': 'Proxy address is Empty',
  'INVALID_ADDRESS': 'Proxy address is NOT address',
  'INVALID_CONTRACT': 'Proxy address is NOT contract',
}
interface Props {
  className?: string,
}

interface IVerifyStatus {
  status: '0' | '1',
  message: string | React.ReactNode,
}

const FormInputContract: React.FC<Props> = ({
  className,
  ...props
}) => {
  const route = useLocation()
  const history = useHistory()

  const [proxyAddress, setProxyAddress] = useState<string>('')
  const [error, setError] = useState<string>('')
  const [verifyStatus, setVerifyStatus] = useState<IVerifyStatus>(DEFAULT_STATUS)

  const [isLoading, setLoading] = useState<boolean>(false)
  const [isShow, setShow] = useState<boolean>(false)

  const onReadFinish = async () => {
    setLoading(true)

    try {
      if (!proxyAddress) {
        throw new Error(ERROR_MSG.EMPTY_ADDRESS)
      }
      // check contract format
      if (!isAddress(proxyAddress)) {
        throw new Error(ERROR_MSG.INVALID_ADDRESS)
      }

      const isContractCode = await isContract(proxyAddress)
      if (!isContractCode) {
        throw new Error(ERROR_MSG.INVALID_CONTRACT)
      }

      history.replace(`/proxycontractchecker?address=${proxyAddress}`)
      const response = await postVerifyProxyAddress(proxyAddress)

      const data = response.data

      const isSuccess = data.status === '1'
      setVerifyStatus({
        message: (
          <RenderIf.Group>
            <RenderIf condition={!isSuccess}>
              <div>{data.result}</div>
            </RenderIf>

            <RenderIf condition>
              <div>{`The proxy's (${proxyAddress}) implementation contract is found at`}</div>
              <SuccessLink
                to={`/address/${data.implement}#code`}
              >{`${data.implement}`}
              </SuccessLink>
              <span>{` and is successfully updated`}</span>
            </RenderIf>
          </RenderIf.Group>
        ),
        status: data.status
      })
      setShow(true)
    } catch (error) {
      setError((error as Error).message)
    } finally {
      setLoading(false)
    }
  }

  const handleCloseModal = () => {
    setShow(false)
    setVerifyStatus(DEFAULT_STATUS)
  }

  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const value = e.target.value

    setProxyAddress(value)
    setError('')
  }

  const handleReset = () => {
    setProxyAddress('')
    setError('')
    setLoading(false)

    history.replace('/proxycontractchecker')
  }

  useEffect(() => {
    const query = new URLSearchParams(route.search);
    const proxyAddress = query.get('address');
    if (proxyAddress) {
      isAddress(proxyAddress)
      setProxyAddress(proxyAddress)
    }
  }, [])

  return (
    <>
      <Form onFinish={onReadFinish}>
        <FormItem>
          <Input
            value={proxyAddress}
            name='proxyContract'
            onChange={handleInputChange}
            style={{ border: 'none' }}
            placeholder={`Proxy address`}
          />
        </FormItem>
        {
          Boolean(error) && (
            <div>
              <TextError>{error}</TextError>
            </div>
          )
        }
        <div
          style={{
            display: 'flex',
            gap: '8px'
          }}
        >
          <ButtonTheme
            loading={isLoading}
            disabled={Boolean(error)}
            theme={{ bg: '#766B60', text: '#fff', textHover: '#fff', bgHover: 'rgba(118,107,96,0.8)' }}
            htmlType="submit"
            style={{ marginTop: "20px" }}
          >
            <span style={{ color: '#fff' }}>{`Verify`}</span>
          </ButtonTheme>
          <ButtonTheme
            theme={{ bg: '#E0DED8', text: '#000', bgHover: '#ece9e0', textHover: '#000' }}
            htmlType="button"
            onClick={handleReset}
            style={{ marginTop: "20px" }}
          >
            {`Reset`}
          </ButtonTheme>
        </div>
      </Form>
      <PopupActionVerify
        open={isShow}
        status={verifyStatus.status}
        message={verifyStatus.message}
        onClose={handleCloseModal}

      />
    </>
  );
};

const ErrorLink = styled(Link)`
  color: #EB201E !important;

  font-weight: 600;
  &:hover{
    text-decoration: underline;
  }
  
`
const SuccessLink = styled(ErrorLink)`
  color: #C0840C !important;
`

const TextError = styled.span`
  color: #EB201E;
`

export default FormInputContract;

