import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
  useLocation
} from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

import { useGeneralContext } from './providers/general'
import './App.css'
// import { ScrollToTop } from './common'
import { Layout } from './appStyles'
import Header from './common/Header/Header'
import Footer from './common/Footer/Footer'

import HomePage from './features/general/HomePage'
import TransactionList from './features/transactions/TransactionListPage'
import TransactionDetailsTransfer from './features/transactions/TransactionDetailsPage'
import BlockListPage from './features/blocks/BlockListPage'
import BlockDetailsPage from './features/blocks/BlockDetailsPage'
import EpochListPage from './features/epochs/EpochListPage'
import EpochDetailsPage from './features/epochs/EpochDetailsPage'
import AccountListPage from './features/accounts/AccountListPage'
import AccountDetailsPage from './features/accounts/AccountDetailsPage'
import MasternodeListPage from './features/masternodes/MasternodeListPage'
import ContractListPage from './features/contracts/ContractListPage'
import TokenListPage from './features/tokens/TokenListPage'
import TokenDetailsPage from './features/tokens/TokenDetailsPage'
import TokenListVrc721Page from './features/tokens/TokenListVrc721Page'
import NotFoundPage from './features/general/NotFoundPage'
import ContractVerify from './features/contracts/ContractVerify'
import RequestLimit from './features/general/RequestLimit'
import CountDownPage from './features/general/CountDownPage'
import ProxyVerify from './features/proxycontractchecker/ProxyVerify'

interface AppLayoutRouteProps {
  component: React.FunctionComponent<any>
  exact: boolean
  path: string
}

export function AppLayoutRoute({
  component: Component,
  ...rest
}:AppLayoutRouteProps) {

  const { pathname } = useLocation()
  const { requestLimit } = useGeneralContext()
  if (requestLimit && pathname !== '/limit') return <Redirect to="/limit" />
  if (!requestLimit && pathname === '/limit') return <Redirect to="/" />

  return (
    <Route
      {...rest}
      render={(props) => (
          <Component {...props} />
      )}
    />
  )
}

function App() {

  return (
    <Router>
      <Helmet>
        <title>Viction - Blockchain Explorer</title>
      </Helmet>
      {/* <ScrollToTop> */}
        <Layout>
          <Header />
          <main className="App">
            <Switch>
              <AppLayoutRoute exact path="/" component={HomePage} />
              <AppLayoutRoute exact path="/txs" component={TransactionList} />
              <AppLayoutRoute exact path="/tx/:hash" component={TransactionDetailsTransfer} />
              <AppLayoutRoute exact path="/txs/:hash" component={TransactionDetailsTransfer} />
              <AppLayoutRoute exact path="/blocks" component={BlockListPage} />
              <AppLayoutRoute exact path="/block/:blockNumberOrHash" component={BlockDetailsPage} />
              <AppLayoutRoute exact path="/epochs" component={EpochListPage} />
              <AppLayoutRoute exact path="/epoch/:epochNumber" component={EpochDetailsPage} />
              <AppLayoutRoute exact path="/accounts" component={AccountListPage} />
              <AppLayoutRoute exact path="/address/:address" component={AccountDetailsPage} />
              <AppLayoutRoute exact path="/masternodes" component={MasternodeListPage} />
              <AppLayoutRoute exact path="/contracts" component={ContractListPage} />
              <AppLayoutRoute exact path="/contracts/verify/:address" component={ContractVerify} />
              <AppLayoutRoute exact path="/contracts/verify/" component={ContractVerify} />
              <AppLayoutRoute exact path="/tokens" component={TokenListPage} />
              <AppLayoutRoute exact path="/tokens/nft" component={TokenListVrc721Page} />
              <AppLayoutRoute exact path="/token/:address" component={TokenDetailsPage} />
              <AppLayoutRoute exact path="/search" component={NotFoundPage} />
              <AppLayoutRoute exact path="/limit" component={RequestLimit} />
              <AppLayoutRoute exact path="/proxycontractchecker" component={ProxyVerify} />
              <AppLayoutRoute exact path="/countdown/:blockNumber" component={CountDownPage} />
              <AppLayoutRoute exact path="*" component={HomePage} />              
            </Switch>
          </main>
          <Footer />
        </Layout>
      {/* </ScrollToTop> */}
    </Router>
  )
}

export default App
