import {
  Form,
  Input,
  Spin
} from 'antd'
import { FunctionComponent, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  ButtonQuery,
  CardContent,
  CardHeader,
  Cards,
  ContractSource,
  FormGroup,
  FormItem,
  LinkStyled,
  NoteInfo,
  TextMain,
  TextOverlay
} from './contractDetailsPageStyles'

import Text from 'antd/lib/typography/Text'
import icNothing from '../../assets/images/status/warning.svg'
import { getContract } from '../../utils'
import { ModalWallet } from '../../common'

const ContractStatus = () => <></>

type DataFormState = {
  data: any
  name: string
  contract: any
}
const FormReadRender: FunctionComponent<DataFormState> = ({ data, name, contract }) => {
  const [dataResult, setDataResult]: Array<any> = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const onReadFinish = async (value: any) => {
    let formatData: Array<any> = []
    setIsLoading(true)
    const params = Object.values(value)
    // Convert data with type []
    const convertParams = params.map((item: any) => {
      if (item.startsWith('[') && item.endsWith(']')) {
        item = JSON.parse(item)
      }
      return item
    })
    try {
      const result = await contract.methods[name](...convertParams).call()
      for (let i = 0; i < data.outputs.length; i++) {
        const output = data.outputs[i]
        const outputRs = output
        let value
        if (typeof result === 'object' && !Array.isArray(result)) {
          value = result[output.name]

          if (value !== 'undefined') {
            value = result[i]
          }
        } else if (Array.isArray(result)) {
          value = JSON.stringify(result)
        } else {
          value = result
        }
        if (output.type === 'address') {
          if (value === 0) {
            value = '0x0000000000000000000000000000000000000000'
          }

          value = value.toLowerCase()
        }

        outputRs.value = value
        formatData.push(outputRs)
      }

    } catch (error: any) {
      formatData.push({
        name: 'Error',
        type: 'string',
        value: error.message
      })
    }
    setDataResult(formatData)
    setIsLoading(false)
  }
  return (
    <Form onFinish={onReadFinish}>
      {
        data.inputs.map((input: any, i: number) => {
          return (
            <FormGroup key={`rf-content-${i}`}>
              <TextMain>{`${input.name} (${input.type})`}</TextMain>
              <FormItem
                name={input.name ? input.name : name + i}
              >
                <Input
                  placeholder={`${input.name} (${input.type})`}
                />
              </FormItem>
            </FormGroup>
          )
        })
      }
      <ButtonQuery htmlType="submit" disabled={isLoading} style={{ marginTop: "20px" }}>
        <TextMain>Query</TextMain>
      </ButtonQuery> &nbsp;
      {isLoading && <Spin size="small" />}
      <div style={{ marginTop: '20px' }}>
        {dataResult.length > 0 && <TextMain>[<b>{name}</b> method response]</TextMain>}
        {dataResult.map((d: any, k: number) => {
          return (
            <div key={`r-${k}`} className={d.name === 'Error' ? 'error' : 'success'}>
              {d.name && <TextMain style={{ marginRight: '10px' }}>{d.name}</TextMain>}
              {<TextOverlay style={{ fontStyle: 'italic' }}>{d.type.toString()}:</TextOverlay>} &nbsp;
              {d.value !== null ? <TextMain>{d.value.toString()}</TextMain> : <TextMain>null</TextMain>} &nbsp;
            </div>
          )
        })
        }
      </div>
    </Form>
  )
}

type ReadOutputState = {
  outputs: any
  name: string
  contract: any
}
const ReadDataOutput: FunctionComponent<ReadOutputState> = ({ outputs, name, contract }) => {
  const [data, setData] = useState()
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await contract.methods[name]().call()
        setData(result)
      } catch (error) {
        console.log(error)
      }
    }
    fetchData()
  }, [])

  function isObject(val: any) {
    return val instanceof Object
  }
  return (
    <>
      {
        outputs.map((s: any, j: Number) => {
          return (
            <div key={`o-content-${j}`}>
              {s.type.indexOf('[]') !== -1 ?
                // @ts-ignore
                Array.isArray(data) && data.map((d: any, i: number) => {
                  return (
                    s.type.indexOf('address') !== -1 ?
                      <div key={`address-${i}`}>
                        <Link to={`/address/${d}`}>{d}</Link> <br />
                      </div>
                      : <TextMain key={`output-${i}`}>{d}</TextMain>
                  )
                })
                : isObject(data) ?
                  <>
                    <TextOverlay>{s.name}:</TextOverlay>&nbsp;
                    <TextMain>
                      {// @ts-ignore
                        data[s.name]
                      }
                    </TextMain>
                  </>
                  :
                  <>
                    {s.type === 'address' ?
                      <Link to={`/address/${data}`}>{data}</Link>
                      : <TextMain>{data}</TextMain>
                    }
                  </>
              }
              < TextOverlay style={{ fontStyle: 'italic', fontSize: '13px', }}> {s.type}</TextOverlay>
            </div>
          )
        })
      }
    </>
  )
}

type DataWriteForm = {
  data: any
  name: string
  abiCode: any
  contractAddress: string
  account: string
  isConnected: boolean
}
const FormWriteRender: FunctionComponent<DataWriteForm> = ({ data, name, abiCode, contractAddress, account, isConnected }) => {
  const [dataResult, setDataResult]: Array<any> = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const onWriteFinish = async (value: any) => {
    let formatData: Array<any> = []
    setIsLoading(true)
    const payableValue = value['payable-value'] ?? null
    // removing payable value from params
    delete value['payable-value']
    const params = Object.values(value)
    const convertParams = params.map((item: any) => {
      if (item.startsWith('[') && item.endsWith(']')) {
        item = JSON.parse(item)
      }
      return item
    })
    try {
      if (window.web3 && account) {
        const contract = new window.web3.eth.Contract(abiCode, contractAddress)
        let sendData: any
        sendData = {
          from: account,
          gasLimit: '0x7A120', // 1000_000
          value: payableValue ? payableValue : null,
        }
        const estGas = await contract.methods[name](...convertParams).estimateGas(sendData)
        if (estGas) {
          sendData.gasLimit = estGas
        }
        const result = await contract.methods[name](...convertParams).send(sendData)
        formatData.push(
          {
            name: 'View your block',
            type: 'blockNumber',
            value: result.blockNumber ? result.blockNumber : ''
          },
          {
            name: 'View your transaction',
            type: 'tx',
            value: result.transactionHash ? result.transactionHash : ''
          }
        )
      }
    } catch (error: any) {
      formatData.push({
        name: 'Error',
        type: 'string',
        value: error.message
      })
    }
    setDataResult(formatData)
    setIsLoading(false)
  }
  return (
    <Form onFinish={onWriteFinish}>
      {data.stateMutability === 'payable' &&
        <FormGroup key={`wf-content-payable-value`}>
          <TextMain>{`payable value (wei)`}</TextMain>
          <FormItem
            name='payable-value'
          >
            <Input placeholder={`payableValue (uint256)`} />
          </FormItem>

        </FormGroup>
      }
      {

        data.inputs.map((input: any, i: number) => {
          return (
            <FormGroup key={`wf-content-${i}`}>
              <TextMain>{`${input.name} (${input.type})`}</TextMain>
              <FormItem
                name={input.name ? input.name : name + i}
              >
                <Input placeholder={`${input.name} (${input.type})`} />
              </FormItem>

            </FormGroup>
          )
        })
      }
      <ButtonQuery htmlType="submit" ><TextMain>Write</TextMain></ButtonQuery> &nbsp; {isLoading && <Spin size="small" />}
      <div style={{ marginTop: '20px' }}>
        {dataResult.length > 0 && <div><TextMain>[<b>{name}</b> method response]</TextMain></div>}
        {dataResult.map((d: any, k: number) => {
          return (
            d.name === 'Error' ?
              <div key={`w-${k}`} className='error'>
                {<TextMain style={{ marginRight: '10px' }}>{d.name}</TextMain>}
                {<TextOverlay style={{ fontStyle: 'italic' }}>{d.type.toString()}:</TextOverlay>}
                {<TextMain>{d.value.toString()}</TextMain>} &nbsp;
              </div>
              :
              <LinkStyled to={d.type === 'blockNumber' ? `/block/${d.value}` : `/tx/${d.value}`}>{d.name}</LinkStyled>
          )
        })
        }
      </div>
    </Form>
  )
}

interface IContentContractUnverified {
  code: string,
  contractAddress: string
}
const ContentContractUnverified: FunctionComponent<IContentContractUnverified> = ({ code, contractAddress }) => {
  return (
    <>
      <NoteInfo>
        <div className='flex-between'>
          <div className='d-flex align-items-center'>
            <img style={{ height: '24px', width: '24px' }} src={icNothing} alt="" />
            <div style={{ marginLeft: '12px' }}>
              <Text style={{ display: 'block' }} strong>Are you the contract creator?</Text>
              <Text style={{ display: 'block' }}>
                Verify and publish your contract source code today.
              </Text>
            </div>
          </div>
          <Link
            style={{
              height: '40px', borderRadius: '1px', border: '1px solid',
              borderColor: '#B47D000',
              padding: '8px 12px',
              background: '#fff',
              color: '#8A6000',
              textAlign: 'center',
            }}
            to={`/contracts/verify/${contractAddress}`}>
            Verify and Publish
          </Link>
        </div>
      </NoteInfo>
      <ContractSource height="20pc">
        {code}
      </ContractSource>
    </>
  )
}


interface IReadContract {
  contractAddress: string,
  proxyTargetContract?: string,
  previousTargetContract?: string,
  proxyType?: string,
  abis: any[],
  isProxy?: boolean,
}
const ReadContract: FunctionComponent<IReadContract> = ({
  contractAddress,
  isProxy,
  proxyTargetContract,
  previousTargetContract,
  proxyType,
  abis
}) => {

  const contractProxyData = abis ? getContract(abis, contractAddress ? contractAddress : '') : ''

  return (
    <>
      {
        isProxy && (
          <div style={{ marginBottom: "20px", display: 'flex', flexDirection: 'column' }}>
            <Text>
              {`ABI for the implementation contract at `}

              <Link to={`/address/${proxyTargetContract}#code`}>
                <Text strong={true} italic={true}>{proxyTargetContract}</Text>
              </Link>

              {proxyType ? `, using ${proxyType} pattern` : ``}
            </Text>

            {
              Boolean(previousTargetContract) && (
                <Text>
                  {`Previously recorded to be on `}

                  <Link to={`/address/${previousTargetContract}#code`}>
                    <Text strong={true} italic={true}>{previousTargetContract}</Text>
                  </Link>

                </Text>
              )
            }
          </div>
        )
      }
      {
        abis.map((v: any, i: number) => {
          return (
            <Cards key={`r-content-${i}`}>
              <CardHeader><TextMain>{`${i + 1}. ${v.name}`}</TextMain></CardHeader>
              <CardContent>
                <div>
                  {v.inputs.length < 1 ?
                    <ReadDataOutput outputs={v.outputs} name={v.name} contract={contractProxyData} />
                    :
                    <FormReadRender data={v} name={v.name} contract={contractProxyData} />
                  }
                </div>
              </CardContent>
            </Cards>
          )
        })
      }
    </>

  )
}

interface IWriteContract {
  contractAddress: string,
  proxyTargetContract?: string,
  previousTargetContract?: string,
  proxyType?: string,
  abis: any[],
  isProxy?: boolean
}
const WriteContract: FunctionComponent<IWriteContract> = ({
  contractAddress,
  proxyTargetContract,
  previousTargetContract,
  proxyType,
  abis,
  isProxy
}) => {
  const [account, setAccount] = useState('')
  const [isConnected, setIsConnected] = useState(false)

  return (
    <div style={{ marginBottom: "20px" }}>
      <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '8px' }}>
        {
          isProxy && (
            <Text>
              {`ABI for the implementation contract at `}
              <Link to={`/address/${proxyTargetContract}#code`}>
                <Text strong={true} italic={true}>{proxyTargetContract}</Text>
              </Link>
              {proxyType ? `, using ${proxyType} pattern` : ``}
            </Text>
          )
        }
        {
          Boolean(previousTargetContract) && (
            <Text>
              {`Previously recorded to be on `}

              <Link to={`/address/${previousTargetContract}#code`}>
                <Text strong={true} italic={true}>{previousTargetContract}</Text>
              </Link>

            </Text>
          )
        }
      </div>
      <ModalWallet
        account={account}
        setAccount={setAccount}
        isConnected={isConnected}
        setIsConnected={setIsConnected}
      />
      {
        contractAddress && abis.map((v: any, i: number) => {
          return (
            <Cards key={`w-content-${i}`}>
              <CardHeader>
                <TextMain>
                  {`${i + 1}. ${v.name} ${v.stateMutability === 'payable' ? '(payable)' : ''}`}
                </TextMain>
              </CardHeader>
              <CardContent>
                <FormWriteRender
                  data={v}
                  name={v.name}
                  abiCode={abis}
                  contractAddress={contractAddress}
                  account={account}
                  isConnected={isConnected}
                />
              </CardContent>
            </Cards>
          )
        })
      }
    </div>
  )
}

ContractStatus.ContractUnverified = ContentContractUnverified
ContractStatus.ReadContract = ReadContract
ContractStatus.WriteContract = WriteContract

export default ContractStatus;