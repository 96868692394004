import {
    CodeFilled,
    CodeTwoTone,
    FileTextOutlined,
    HeatMapOutlined,
    OrderedListOutlined
} from '@ant-design/icons'
import {
    Col,
    Row,
    Tabs,
    Tooltip
} from 'antd'
import { useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import {
    BlockCenter,
    ContractSource,
    StyleCopyIcon,
    SwitchButton,
    TitleHeaderBox,
    TxtColStyle
} from './contractDetailsPageStyles'

import { Link } from 'react-router-dom'
import iconCopy from '../../assets/images/icon/icon-copy.svg'
import icUnfillWarning from '../../assets/images/status/unfill-warning.svg'
import CheckStatus from '../../common/CheckStatus'
import ContractStatus from './ContractStatus'
import { getContract } from '../../utils'
import styled from 'styled-components'

const { TabPane } = Tabs
type ContractState = {
    data: any
    address: string
}

const SolidityRender = ({ data }: any) => {
    const [copied, setCopied] = useState(false)

    return (
        <>
            <TitleHeaderBox style={{ marginTop: "20px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <div><CodeFilled /> {data[0]} </div>
                <Tooltip placement="top" title={copied ? "Copied" : "Copy to clipboard"}>
                    <CopyToClipboard
                        text={data[1].content}
                        onCopy={() => setCopied(true)}
                        //@ts-ignore
                        onMouseEnter={() => setCopied(false)}
                    >
                        <StyleCopyIcon style={{ marginRight: '10px' }}><span><img width="20" src={(iconCopy)} alt="icon copy" /></span></StyleCopyIcon>
                    </CopyToClipboard>
                </Tooltip>
            </TitleHeaderBox>
            <ContractSource height="20pc">
                {data[1].content}
            </ContractSource>
        </>
    )
}

const ContentOfTheContractTab = ({ data, address }: ContractState) => {
    const [copied, setCopied] = useState(false)
    const [isByteCode, setIsByteCode] = useState(true)

    const sourceCode = data.contractData && data.contractData.sourceCode ? Object.entries(data.contractData.sourceCode) : []
    let readData: Array<any> = []
    let writeData: Array<any> = []
    let readProxyData: Array<any> = []
    let writeProxyData: Array<any> = []

    const abiContract = data.contractData.abiCode ? JSON.parse(data.contractData.abiCode) : null
    const contractData = abiContract ? getContract(abiContract, address ? address : '') : ''
    readData = abiContract && abiContract.filter((abi: any) => {
        return abi.outputs && abi.constant
    })
    writeData = abiContract && abiContract.filter((abi: any) => {
        return abi.outputs && !abi.constant
    })

    const abiProxyContract = data.contractData?.proxyTargetContractData?.abiCode ? JSON.parse(data.contractData?.proxyTargetContractData?.abiCode) : null
    const contractProxyData = abiProxyContract ? getContract(abiProxyContract, address ? address : '') : ''
    readProxyData = abiProxyContract && abiProxyContract.filter((abi: any) => {
        return abi.outputs && abi.constant
    })
    writeProxyData = abiProxyContract && abiProxyContract.filter((abi: any) => {
        return abi.outputs && !abi.constant
    })
    const isContractVerify = Boolean(data.contractData?.sourceCode)

    if (Boolean(abiProxyContract) && !isContractVerify) {
        return (
            <>
                <SectionRelative>
                    <LinkVerifyProxy
                        to={`/proxycontractchecker?address=${address}`}
                    >
                        <BlockCenter style={{ gap: '4px' }}>
                            <img style={{ height: '24px', width: '24px' }} src={icUnfillWarning} alt="" />
                            Is this a proxy?
                        </BlockCenter>
                    </LinkVerifyProxy>
                </SectionRelative>
                <Tabs type="card" key="sub-tab">
                    <TabPane tab="Code" key="2.1">
                        <ContractStatus.ContractUnverified
                            code={data.code}
                            contractAddress={address}
                        />
                    </TabPane>
                    <TabPane tab="Read as Proxy" key="2.2">
                        <TitleHeaderBox><FileTextOutlined />  Read Contract as Proxy</TitleHeaderBox>
                        <ContractStatus.ReadContract
                            contractAddress={address}
                            proxyTargetContract={data.contractData.proxyTargetContract}
                            previousTargetContract={data.contractData.previousTargetContract}
                            proxyType={data.contractData.proxyType}
                            abis={readProxyData}
                            isProxy
                        />
                    </TabPane>
                    <TabPane tab="Write as Proxy" key="2.3">
                        <TitleHeaderBox><FileTextOutlined />  Write Contract as Proxy</TitleHeaderBox>
                        <ContractStatus.WriteContract
                            contractAddress={address}
                            proxyTargetContract={data.contractData.proxyTargetContract}
                            previousTargetContract={data.contractData.previousTargetContract}
                            proxyType={data.contractData.proxyType}
                            abis={writeProxyData}
                            isProxy
                        />
                    </TabPane>
                </Tabs>
            </>
        )
    }

    if (!isContractVerify) {
        return (
            <>
                <LinkUnVerifyProxy
                    to={`/proxycontractchecker?address=${address}`}
                >
                    <BlockCenter style={{ gap: '4px' }}>
                        <img style={{ height: '24px', width: '24px' }} src={icUnfillWarning} alt="" />
                        Is this a proxy?
                    </BlockCenter>
                </LinkUnVerifyProxy>
                <ContractStatus.ContractUnverified
                    code={data.code}
                    contractAddress={address}
                />
            </>
        )
    }

    return (
        <>
            <SectionRelative>
                <LinkVerifyProxy
                    to={`/proxycontractchecker?address=${address}`}
                >
                    <BlockCenter style={{ gap: '4px' }}>
                        <img style={{ height: '24px', width: '24px' }} src={icUnfillWarning} alt="" />
                        Is this a proxy?
                    </BlockCenter>
                </LinkVerifyProxy>
            </SectionRelative>
            <Tabs type="card" key="sub-tab">
                <TabPane tab="Code" key="2.1">
                    <TitleHeaderBox>
                        <CheckStatus tokenStatus={data.tokenData} contractVerified={isContractVerify} />
                    </TitleHeaderBox>
                    <Row gutter={24}>
                        <Col span={24} md={12}>
                            <Row gutter={24}>

                                <Col span={24} md={5} lg={8}>
                                    <TxtColStyle className="col-txt-left">
                                        Contract Name:
                                    </TxtColStyle>
                                </Col>
                                <Col span={24} md={19} lg={16}>
                                    <TxtColStyle className="col-txt-right">
                                        {data.contractData.contractName}
                                    </TxtColStyle>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24} md={5} lg={8}>
                                    <TxtColStyle className="col-txt-left">
                                        Compiler Version
                                    </TxtColStyle>
                                </Col>
                                <Col span={24} md={19} lg={16}>
                                    <TxtColStyle className="col-txt-right">
                                        {data.contractData.compiler}
                                    </TxtColStyle>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24} md={12}>
                            <Row gutter={24}>
                                <Col span={24} md={5} lg={8}>
                                    <TxtColStyle className="col-txt-left">
                                        Optimization:
                                    </TxtColStyle>
                                </Col>
                                <Col span={24} md={19} lg={16}>
                                    <TxtColStyle className="col-txt-right">
                                        {data.contractData.optimization ? "Yes" : "No"}
                                    </TxtColStyle>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    {
                        sourceCode.length > 0 && sourceCode.map((value: any, i: any) => {
                            return (
                                <SolidityRender key={`s-${i}`} data={value} />
                            )
                        })
                    }
                    {data.contractData.abiCode &&
                        <>
                            <TitleHeaderBox style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <div><OrderedListOutlined /> Contract ABI</div>
                                <Tooltip placement="top" title={copied ? "Copied" : "Copy to clipboard"}>
                                    <CopyToClipboard
                                        text={data.contractData.abiCode}
                                        onCopy={() => setCopied(true)}
                                        //@ts-ignore
                                        onMouseEnter={() => setCopied(false)}
                                    >
                                        <StyleCopyIcon style={{ marginRight: '10px' }}><span><img width="20" src={(iconCopy)} alt="icon copy" /></span></StyleCopyIcon>
                                    </CopyToClipboard>
                                </Tooltip>
                            </TitleHeaderBox>

                            <ContractSource height="10pc">
                                {data.contractData.abiCode}
                            </ContractSource>
                        </>
                    }
                    {data.code &&
                        <>
                            <TitleHeaderBox style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <div><CodeTwoTone /> Contract Creation Code</div>
                                <Tooltip placement="top" title={copied ? "Copied" : "Copy to clipboard"}>
                                    <CopyToClipboard
                                        text={data.code}
                                        onCopy={() => setCopied(true)}
                                        //@ts-ignore
                                        onMouseEnter={() => setCopied(false)}
                                    >
                                        <StyleCopyIcon style={{ marginRight: '10px' }}><span><img width="20" src={(iconCopy)} alt="icon copy" /></span></StyleCopyIcon>
                                    </CopyToClipboard>
                                </Tooltip>
                            </TitleHeaderBox>
                            <ContractSource height="10pc">
                                {data.code}
                            </ContractSource>
                        </>
                    }
                    {data.contractData.deployedBytecode &&
                        <>
                            <TitleHeaderBox style={{ display: "flex", justifyContent: "space-between" }}>
                                <div>
                                    <CodeTwoTone /> {isByteCode ? 'ByteCode' : 'Opcodes'}
                                </div>
                                <div style={{ display: "flex" }}>
                                    <SwitchButton
                                        onClick={() => {
                                            setIsByteCode(!isByteCode)
                                        }}>
                                        {`Switch to ${isByteCode ? 'Opcodes View' : 'ByteCode'}`}
                                    </SwitchButton>
                                    <Tooltip placement="top" title={copied ? "Copied" : "Copy to clipboard"}>
                                        <CopyToClipboard
                                            text={isByteCode ? data.contractData.deployedBytecode.object : data.contractData.deployedBytecode.opcodes}
                                            onCopy={() => setCopied(true)}
                                            //@ts-ignore
                                            onMouseEnter={() => setCopied(false)}
                                        >
                                            <StyleCopyIcon style={{ marginRight: '10px' }}><span><img width="20" src={(iconCopy)} alt="icon copy" /></span></StyleCopyIcon>
                                        </CopyToClipboard>
                                    </Tooltip>
                                </div>
                            </TitleHeaderBox>
                            {isByteCode ?
                                <ContractSource height="10pc">
                                    {data.contractData.deployedBytecode.object}
                                </ContractSource>
                                :
                                <ContractSource height="10pc">
                                    {data.contractData.deployedBytecode.opcodes}
                                </ContractSource>
                            }
                        </>
                    }
                    {data.contractData.deployedBytecode &&
                        <>
                            <TitleHeaderBox style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <div>
                                    <HeatMapOutlined /> Deployed ByteCode Sourcemap
                                </div>
                                <Tooltip placement="top" title={copied ? "Copied" : "Copy to clipboard"}>
                                    <CopyToClipboard
                                        text={data.contractData.deployedBytecode.sourceMap}
                                        onCopy={() => setCopied(true)}
                                        //@ts-ignore
                                        onMouseEnter={() => setCopied(false)}
                                    >
                                        <StyleCopyIcon style={{ marginRight: '10px' }}><span><img width="20" src={(iconCopy)} alt="icon copy" /></span></StyleCopyIcon>
                                    </CopyToClipboard>
                                </Tooltip>
                            </TitleHeaderBox>
                            <ContractSource height="6pc">
                                {data.contractData.deployedBytecode.sourceMap}
                            </ContractSource>
                        </>
                    }
                </TabPane>
                <TabPane tab="Read" key="2.2">
                    {readData?.length > 0 &&
                        <>
                            <TitleHeaderBox><FileTextOutlined />  Read Contract Information</TitleHeaderBox>
                            <ContractStatus.ReadContract
                                contractAddress={address}
                                abis={readData}
                                isProxy={false}
                            />
                        </>
                    }
                </TabPane>
                <TabPane tab="Write" key="2.3">
                    {writeData?.length > 0 &&
                        <>
                            <TitleHeaderBox><FileTextOutlined />  Write Contract Information</TitleHeaderBox>
                            <ContractStatus.WriteContract
                                contractAddress={address}
                                abis={writeData}
                                isProxy={false}
                            />
                        </>
                    }
                </TabPane>
                {readProxyData &&
                    <TabPane tab="Read as Proxy" key="2.4">
                        {readProxyData.length > 0 &&
                            <>
                                <TitleHeaderBox><FileTextOutlined />  Read Contract as Proxy</TitleHeaderBox>
                                <ContractStatus.ReadContract
                                    contractAddress={address}
                                    proxyTargetContract={data.contractData.proxyTargetContract}
                                    previousTargetContract={data.contractData.previousTargetContract}
                                    proxyType={data.contractData.proxyType}
                                    abis={readProxyData}
                                    isProxy
                                />
                            </>
                        }
                    </TabPane>
                }
                {writeProxyData &&
                    <TabPane tab="Write as Proxy" key="2.5">
                        {writeProxyData.length > 0 &&
                            <>
                                <TitleHeaderBox><FileTextOutlined />  Write Contract as Proxy</TitleHeaderBox>
                                <ContractStatus.WriteContract
                                    contractAddress={address}
                                    proxyTargetContract={data.contractData.proxyTargetContract}
                                    previousTargetContract={data.contractData.previousTargetContract}
                                    proxyType={data.contractData.proxyType}
                                    abis={writeProxyData}
                                    isProxy
                                />
                            </>
                        }
                    </TabPane>
                }
            </Tabs>
        </>
    )
}

const LinkUnVerifyProxy = styled(Link)`
    padding: 8px;
    font-size: 14px;
    font-weight: 600;
    background: #0000000D;
    cursor: pointer;
    border-radius: 2xp;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 150px;
    margin-left: auto;
    margin-bottom: 16px;
`

const SectionRelative = styled.div`
    width: 100%;

    position: relative;

    z-index: 10;
    

`

const LinkVerifyProxy = styled(LinkUnVerifyProxy)`
    position: absolute;
    right: 0;
    top: 100%;

    @media only screen and (max-width: 1024px) { {
        position: static;

        margin-left: 0;
    }

`
export default ContentOfTheContractTab