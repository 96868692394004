
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Col, Row, Form, Select, Spin } from 'antd'
import { fetchCompilerVersion, fetchContractVerify } from '../../api'
import { TokenParamsType } from '../../constants/types'
import { PageHeader } from '../../common'
import {
    MinusCircleOutlined,
    PlusOutlined
} from '@ant-design/icons'
import {
    Box,
    TitleHeaderBox,
    NoteInfo,
    FormGroup,
    InputStyled,
    FieldExtend,
    StatusStyled,
    ButtonSubmit,
    TextAreaStyled,
    SelectStyled,
    AddSource,
} from './contractDetailsPageStyles'
import TextArea from 'antd/lib/input/TextArea'
import { useHistory } from 'react-router-dom'

const { Option } = Select

// using default paris (The Merge)
const VALID_EVM_VERSION = [
    'paris',
    'cancun',
    'shanghai',
    'london',
    'istanbul',
    'petersburg',
    'constantinople',
    'byzantium',
    'spuriousDragon',
    'tangerineWhistle',
    'homestead',
  ]

export default function ContractVerify() {
    const history = useHistory()
    const [compilerVersion, setCompilerVersion] = useState([])
    const [loading, setLoading] = useState(false)
    const [mgs, setMgs]: Array<any> = useState([])
    const [form] = Form.useForm()
    const { address } = useParams<TokenParamsType>()
    useEffect(() => {
        const fetCompiler = async () => {
            try {
                const result = await fetchCompilerVersion()
                setCompilerVersion(result.data.data)
            } catch (e) {
                console.log(e)
            }
        }
        fetCompiler()
    }, [])

    let formMgs: Array<any> = []
    const onFinish = async (values: any) => {
        setLoading(true)
        values.runs = Number(values.runs || 0)
        if (!values.sourceCode) {
            formMgs.push({
                status: 'warning',
                mgs: 'Warning: Please add Solidity source code!'
            })
        } else {
            try {
                await fetchContractVerify(values)
                formMgs.push({
                    status: 'success',
                    mgs: 'Contract verified successfull!'
                })
                history.push(`/address/${address}/#code`)
            } catch (e) {
                formMgs.push({
                    status: 'error',
                    mgs: e.response && e.response.data ? e.response.data.errors[0].message : 'Error: An unknown error. Please reload the website and try again'
                })
            }
        }
        setLoading(false)
        setMgs(formMgs)

    }
    return (
        <>
            <PageHeader />
            <Box>
                <TitleHeaderBox style={{ fontSize: "24px" }}>Verify and Publish your Solidity Source Code</TitleHeaderBox>
                <NoteInfo>Step 1: Enter your Contract Source Code below.</NoteInfo>
                <NoteInfo>Step 2: If the Bytecode generated matches the existing Creation Address Bytecode, the contract is then Verified.</NoteInfo>
                <NoteInfo>Step 3: Contract Source Code is published online and publicably verifiable by anyone</NoteInfo>
                <FormGroup style={{ paddingTop: "30px" }}>
                    <Form layout="vertical" form={form} onFinish={onFinish}>
                        <Row gutter={24}>
                            <Col span={24} md={8} lg={8}>
                                <Form.Item
                                    name="contractAddress"
                                    label="Contract Address"
                                    rules={[{ required: true, message: 'Please select a value' }]}
                                    initialValue={address ? address : ''}
                                >
                                    <InputStyled placeholder="Enter contract address" required />
                                </Form.Item>
                            </Col>
                            <Col span={24} md={8} lg={8}>
                                <Form.Item
                                    name="contractName"
                                    label="Contract Name"
                                    rules={[{ required: true, message: 'Please enter a contract name' }]}
                                >
                                    <InputStyled placeholder="Enter contract name" required />
                                </Form.Item>
                            </Col>
                            <Col span={24} md={8} lg={8}>
                                <SelectStyled>
                                    <Form.Item
                                        name="version"
                                        label="Compiler"
                                        hasFeedback
                                        rules={[{ required: true, message: 'Please select a value' }]}
                                    >
                                        <Select placeholder="Please select a value" >
                                            {compilerVersion.length > 0 &&
                                                compilerVersion.map((v: any, key: number) => {
                                                    return (
                                                        <Option key={`item${key}`} value={v}>{v}</Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </SelectStyled>
                            </Col>

                        </Row>
                        <Row gutter={24}>
                            <Col span={24} md={4} lg={8}>
                                <SelectStyled>
                                    <Form.Item
                                        name="optimization"
                                        label="Optimization"
                                        hasFeedback
                                        rules={[{ required: true, message: 'Please select a value' }]}
                                        initialValue={false}
                                    >
                                        <Select placeholder="Please select a value">
                                            <Option value={false}>No</Option>
                                            <Option value={true}>Yes</Option>
                                        </Select>
                                    </Form.Item>
                                </SelectStyled>
                            </Col>
                            <Col span={24} md={8} lg={8}>
                                <SelectStyled>
                                    <Form.Item
                                        name="runs"
                                        label="Runs"
                                    >
                                        <InputStyled placeholder="Enter runs number" type='number' />
                                    </Form.Item>
                                </SelectStyled>
                            </Col>
                            <Col span={24} md={8} lg={8}>
                                <SelectStyled>
                                    <Form.Item
                                        name="evmVersion"
                                        label="EVM Version"
                                        initialValue={'paris'}
                                        rules={[{ required: true, message: 'Please select a value' }]}
                                    >
                                        <Select placeholder="Please select a value">
                                            {
                                                VALID_EVM_VERSION.map(version => {
                                                    return (
                                                        <Option key={version} value={version}>
                                                            {version}
                                                        </Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </SelectStyled>
                            </Col>
                        </Row>
                        <Form.Item
                            name="constructorArguments"
                            label="Constructor Arguments ABI-encoded (for contracts that were created with constructor parameters)"
                            rules={[{ required: false, message: 'Please enter a contract constructor arguments' }]}
                        >
                            <TextArea rows={3} placeholder="Enter contract constructor arguments.
                            Example:
                            0000000000000000000000007a250d5630b4cf539739df2c5dacb4c659f2488d000000000000000000000000e592427a0aece92de3edee1f18e0157c05861564000000000000000000000000c02aaa39b223fe8d0a0e5c4f27ead9083c756cc2"/>
                        </Form.Item>
                        <Row>
                            {/* <Col span={24} md={24} lg={24}>
                                <FieldExtend>
                                    <Form.Item
                                        name="sourceName"
                                        label="File name"
                                        rules={[{ required: true, message: 'Please enter file name' }]}
                                    >
                                        <InputStyled required />
                                    </Form.Item>
                                    <Form.Item
                                        name="sourceCode"
                                        label="Source Code"
                                        rules={[{ required: true, message: 'Please enter Source Code content' }]}
                                    >
                                        <TextArea rows={4} />
                                    </Form.Item>
                                </FieldExtend>
                            </Col> */}
                            <Form.List name="sourceCode">
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map(({ key, name, fieldKey, ...restField }) => (
                                            <FieldExtend key={`solididy-${key}`}>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'fileName']}
                                                    label="File name"
                                                    fieldKey={[fieldKey || '', 'fileName']}
                                                    rules={[{ required: true, message: 'Please enter file name' }]}
                                                >
                                                    <InputStyled placeholder="Enter file name. eg: LUA.sol" />
                                                </Form.Item>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'content']}
                                                    label="Solidity Source Code"
                                                    fieldKey={[fieldKey || '', 'content']}
                                                    rules={[{ required: true, message: 'Please enter source code' }]}
                                                >
                                                    <TextAreaStyled rows={15} placeholder="Solidity source code" />
                                                </Form.Item>
                                                <MinusCircleOutlined onClick={() => remove(name)} />
                                            </FieldExtend>
                                        ))}
                                        <Form.Item>
                                            <AddSource type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                Add Solidity Source Code
                                            </AddSource>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                        </Row>
                        <Form.Item>
                            <ButtonSubmit type="submit" value="Submit" disabled={loading} />  &nbsp; {loading && <Spin size="small" />}
                        </Form.Item>
                    </Form>

                    {mgs.length > 0 && <StatusStyled status={mgs[0].status}>{mgs[0].mgs}</StatusStyled>}
                </FormGroup>
            </Box>
        </>
    )
}
