import { Button, Typography } from 'antd'
import { useHistory } from 'react-router-dom'

import { useSelector } from 'react-redux'
import { useQuery } from '../../app/hooks'
import { RootState } from '../../app/store'
import errorImg from '../../assets/images/404.svg'
import { SearchBox } from '../../common'
import { Container, SearchWrapper, Warning } from './notFoundPageStyles'

const { Paragraph, Text, Title } = Typography

function NotFoundPage() {
  const query = useQuery()
  const history = useHistory()
  const mode = useSelector((state: RootState) => state.general.mode)

  function retry() {
    const searchString = query.get('q')?.trim() || ''
    let searchType
    
    switch (true) {
      case searchString.length === 66:
        searchType = 'tx'
        break;

      case !searchString.match(/\D+/):
        searchType = 'block'
        break;

      default:
        searchType = 'address'
        break;
    }

    if (searchString && searchType) {
      history.push(`/${searchType}/${searchString}`)
    }
  }

  return (
    <Container>
      <Warning>
        <div className="img-visual"><img src={errorImg} alt="" /></div>
        <div className={(mode === 'dark' ? mode : '') + " mt-3"}>
          <Title level={2}>Search not found</Title>
          <Paragraph>Oops! The search string you entered was: <Text strong>&quot;{query.get("q")}&quot;</Text></Paragraph>
          <Paragraph>Sorry! This is an invalid search string.</Paragraph>
        </div>
        <SearchWrapper className="mt-3">
          <SearchBox />
        </SearchWrapper>
        <div className="mt-3">
          <Button className="btn-blue-bd-blue" onClick={retry}>Retry</Button>
        </div>
      </Warning>
    </Container>
  )
}

export default NotFoundPage
