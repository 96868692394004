import { Button, Form } from "antd";
import styled from "styled-components";

export const MainContent = styled.div`
  display: flex;
  gap: 1px;

  @media(max-width: 1024px){
    flex-direction: column;
  }
`

export const Section = styled.div`
  padding: 24px;
  background: #fff;

  @media(max-width: 1024px){
    width: 100% !important;
    max-width: 100% !important;
  }
`

export const Quotation = styled.div`
  background: #F5F5F5;
  display: flex;

  padding: 12px;

  gap: 8px;
`

export const BlockCol = styled.div`
  display: flex;
  flex-direction: column;
`

export const FormItem = styled(Form.Item)`
  border: 1px solid #EBE9E5;
  margin-bottom: 0;
  border-radius: 0;
  margin-top: 12px;
  input{
    background-color: ${({ theme }) => theme.bg2};
    color: ${({ theme }) => theme.textOverlay};
  }
  
`

export const ButtonTheme = styled(Button)`
  cusor: pointer;
  background-color: ${({ theme }) => theme.bg} !important;
  padding: 5px 15px;
  border: none;
  margin-top: 10px;
  border-radius: 0;
  color: ${({ theme }) => theme.text} !important;
  margin-top: 20px;
  cursor: pointer;
  &:hover{
    background-color: ${({ theme }) => theme.bgHover} !important;
    color: ${({theme}) => theme.textHover} !important;
  }
  &:disabled{
    opacity: 0.5;

    background-color: ${({ theme }) => theme.bg} !important;
  }
`